<template>
  <div class="introduce">
    <div class="topImg">
      <img src="./img/introduce.png" alt="" />
    </div>
    <div class="body">
      <div class="body-c">
        <div class="title"><span>未来社区介绍</span></div>
        <p class="te">
          和丰社区地处宁波市东外滩核心区域，依托百年和丰历史底蕴，融合居民、商户和创业人才三类人群，引入“场景联合体”共建融合，通过“积分体系”有效串联，打造了以“和众丰彩织未来”为主题的产城融合型未来社区。
        </p>
        <img src="./img/introDetail.jpg" alt="" />
        <p>
          未来社区是共同富裕现代化基本单元，是群众感受美好生活的普遍向往地，也是数字社会场景应用的最佳实践地。
        </p>
        <p>
          和丰未来社区是居民共建和邻里自治，它实现了从“你我”到“我们”的转变。
        </p>
        <p>未来，生活更美好！</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.introduce {
  min-height: 100vh;
  padding: 0 0 68px;
  background: rgba(41, 146, 253, 1);
  .topImg {
    height: 600px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .body {
    padding: 0 32px;
    margin-top: -222px;
    z-index: 99;
    position: relative;
    .body-c {
      background: #ffffff;
      border-radius: 16px;
      padding: 48px 54px 70px;
      .title {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: #2992fd;
        margin-bottom: 22px;
        line-height: 44px;
        span {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            left: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &::after {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            right: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(180deg);
          }
        }
      }
      .te {
        margin: 0;
      }
      img {
        width: 100%;
        border-radius: 16px;
      }
      p {
        text-indent: 2em;
        font-size: 32px;
        font-weight: 400;
        color: #646668;
        margin-bottom: 60px;
        line-height: 64px;
      }
    }
  }
}
</style>
